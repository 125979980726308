export default {
  colors: {
    lime: "#CCFF00",
    orange: "#D65337",
    brown: "#513628",
    grey: '#D9D9D9',
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
};
